import { SymbolInput } from "./../config/type";
import { JsonRpcProvider } from "near-api-js/lib/providers";
import { connect, Near, WalletConnection } from "near-api-js";
import { AccountBalance } from "near-api-js/lib/account";
import config from "../config";
import { Amount } from "allstake-sdk";
import Big from "big.js";
import {
  NEAR_BALANCE_GAS_RESERVATION,
  SOL_BALANCE_GAS_RESERVATION,
} from "../constants";
import { getSolBalance, getTokenAmount } from "./rpc";

// near wallet instance
let nearWallet: WalletConnection | undefined;

// near instance
let near: Near | undefined;

// json rpc provider
let rpcProvider: JsonRpcProvider | undefined;

export async function getNear() {
  if (!config.near) {
    throw new Error("near env config not found");
  }

  if (near) return near;

  // get near instance
  near = new Near(config.near);
  return near;
}

export async function getWallet(): Promise<WalletConnection> {
  if (!config.near) {
    throw new Error("near env config not found");
  }

  if (nearWallet) return nearWallet;

  // connect to NEAR
  const near = await connect(config.near);

  // create wallet connection
  nearWallet = new WalletConnection(near, "");

  return nearWallet;
}

export async function getRpcProvider() {
  if (rpcProvider) return rpcProvider;

  if (!config.near) {
    throw new Error("near env config not found");
  }

  rpcProvider = new JsonRpcProvider({ url: config.near.nodeUrl });

  return rpcProvider;
}

export async function getSolTokenBalanceCombine(
  userAddress: string,
  symbolInput: SymbolInput,
): Promise<number> {
  if (symbolInput.symbol === "SOL") {
    const balance = await getSolBalance(userAddress);
    const balanceResult = Big(balance).minus(SOL_BALANCE_GAS_RESERVATION);
    return balanceResult.lt(0) ? 0 : balanceResult.toNumber();
  } else {
    return (await getTokenAmount(symbolInput.address, userAddress)).uiAmount;
  }
}
export async function getNearTokenBalanceCombine(
  accountId: string,
  symbolInput: SymbolInput,
) {
  if (symbolInput.symbol === "NEAR") {
    const balance = await getNearBalance(accountId);
    const balanceResult = Big(
      Amount.format(balance.available, symbolInput.decimals),
    ).minus(NEAR_BALANCE_GAS_RESERVATION);
    return balanceResult.lt(0) ? "0" : balanceResult.toFixed();
  } else {
    const balance = await getNearTokenBalance(accountId, symbolInput.address);
    return Amount.format(balance, symbolInput.decimals);
  }
}
export async function getNearTokenBalance(
  accountId: string,
  contractId: string,
) {
  const near = await getNear();
  const account = await near.account(accountId);
  return await account.viewFunction({
    contractId: contractId,
    methodName: "ft_balance_of",
    args: {
      account_id: accountId,
    },
  });
}

export async function getNearBalance(
  accountId: string,
): Promise<AccountBalance> {
  const near = await getNear();
  const account = await near.account(accountId);
  const balance = await account.getAccountBalance();
  return balance;
}

export async function signIn(): Promise<void> {
  window.modal.show();
}

export async function signOut(): Promise<void> {
  const wallet = await window.selector.wallet();
  wallet.signOut().catch((e: Error) => {
    console.error(e);
  });
}

export function isSignedInWithSender(): boolean {
  return (window.near &&
    window.near.isSender &&
    !!window.near.getAccountId()) as boolean;
}

/**
 * sender & okex wallet are plugin wallet
 */
export async function isWebWallet() {
  if (!window.selector || !window.selector.wallet) return false;
  try {
    const wallet = await window.selector.wallet();
    return ![
      "sender",
      "wallet-connect",
      "here-wallet",
      "meteor-wallet",
      "okx-wallet",
    ].includes(wallet.id);
  } catch (e) {
    return false;
  }
}
